import React, { Component } from 'react'
import styled from 'styled-components'
import { SongsContext } from 'utils/song-context'

class SongSampleText extends Component {

  static contextType = SongsContext
  
  constructor () {
    super()

    this.englishSample = React.createRef()
    this.chineseSample = React.createRef()
    this.sampleText = React.createRef()
  }

  componentDidMount () {
    const englishWidth = this.englishSample.current.offsetWidth
    const chineseWidth = this.chineseSample.current.offsetWidth

    this.context.setScaleFactor(chineseWidth / englishWidth)
  }

  render () {
    return (
      <SampleStyle ref={this.sampleText}>
        <pre ref={this.englishSample}>1-2|3(4)5&nbsp;aAbBcCdDeE</pre>
        <pre ref={this.chineseSample}>一二三四五六七八九十</pre>
      </SampleStyle>
    )
  }
}

export default SongSampleText

const SampleStyle = styled.div`

  
  font-family: monospace;
  font-size: 1.5rem;

  pre {
    display: inline-block;
  }
  
  position: absolute;
  left: -1000px;
  top: -1000px;
  z-index: 99999;   

`
