import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import API, { Oauth } from 'utils/API'
import ScrollToTop from 'utils/ScrollToTop'
import { SongsContext } from 'utils/song-context'

import SongSampleText from 'components/song/SongSampleText'

import Song from 'pages/Song'
import Home from 'pages/Home'
import SongListing from 'pages/SongListing'
import Contact from 'pages/Contact'
import Search from 'pages/Search'
import { Offline } from 'pages/Offline'
import { Error404 } from 'pages/Error404'
import { Page12 } from 'layouts/Page12'

import 'css/styles.css'

class App extends Component {

  static contextType = SongsContext

  constructor () {
    super()

    this.englishSample = React.createRef()
    this.chineseSample = React.createRef()
    this.sampleText = React.createRef()

    this.state = {
      siteOnline: null,
      songLastUpdated: 0,
      offlineMessage: '',
      lastUpdated: '',
      statusIsFetched: false,
      fetchFailed: false,

      accessToken: '',
      authFailed: false,
      accessTokenExpires: 0,

      isFetching: true,
      scaleFactor: 1,
      songs: [],
      hero: null,
      message: ''
    }
  }

  componentDidMount () {
    this.getSiteStatus()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.siteOnline === true) {
      if (this.accessTokenNeedsRefresh() === true) {
        this.getAuthToken()
      }

      if (this.state.accessToken !== '') {
        if (this.songListNeedsRefresh() === true) {
          this.fetchSongs()
        } else {
          if (this.state.songs.length === 0) {
            this.setState({
              songs: JSON.parse(localStorage.getItem('songs'))
            })
          }
        }
      }
    }
  }

  accessTokenNeedsRefresh () {
    // Get access token if expire is unknown.
    if (this.state.accessToken === '') {
      return true
    }

    // Get access token if current token will expire in 10 seconds.
    const accessTokenExpires = localStorage.getItem('accessTokenExpires')

    if (accessTokenExpires - Date.now() < 10000) {
      return true
    }

    return false
  }

  songListNeedsRefresh () {
    // Refresh song list if it is empty.
    if (localStorage.getItem('songs') === null) {
      return true
    }

    // Refresh song list if the list is outdated.
    const songLastUpdated =
      localStorage.getItem('songLastUpdated') ? localStorage.getItem('songLastUpdated') : 0
    if (this.state.songLastUpdated > songLastUpdated) {
      return true
    }

    return false
  }

  async getSiteStatus () {
    try {
      const response = await API.get('/kenli1002/config')

      const data = response.data[0]
      let siteOnline = false

      if (data.siteOnline === 'true') {
          siteOnline = true
      }
      
      const hero = {
        xs: data.hero_xs.replace(/^\/+/, ''),
        sm: data.hero_sm.replace(/^\/+/, ''),
        md: data.hero_md.replace(/^\/+/, ''),
        lg: data.hero_lg.replace(/^\/+/, ''),
        wide: data.hero_wide.replace(/^\/+/, '')
      }

      this.setState({
        siteOnline: siteOnline,
        songLastUpdated: data.songLastUpdated,
        lastUpdated: data.lastUpdated,
        offlineMessage: data.offlineMessage,
        hero: hero,
        statusIsFetched: true
      })

      localStorage.setItem('songLastUpdated', data.songLastUpdated)
    } catch (e) {
      this.setState({
        fetchFailed: 'ture'
      })
    }
  }

  async getAuthToken () {
    try {
      const respond = await Oauth()
      const data = respond.data
      const accessTokenExpires = Date.now() + data.expires_in * 1000

      this.setState({
        accessToken: data.access_token,
        accessTokenExpires: accessTokenExpires
      })

      localStorage.setItem('accessTokenExpires', accessTokenExpires)
    } catch (e) {
      this.setState({
        authFailed: true,
        message: '無法讀取數據，請稍候重試。'
      })
    }
  }

  async fetchSongs () {
    if (this.state.accessToken !== '') {
      try {
        const response = await API.get('kenli1002/songs-keys', {
          timeout: 10000,
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`
          }
        })

        const songs = this.getSongList(response.data)

        this.setState({
          songs: songs,
          isFetching: false
        })

        localStorage.setItem('songs', JSON.stringify(songs))
      } catch (e) {
        this.setState({
          isFetching: false,
          fetchFailed: true
        })
      }
    }
  }

  // Combine the same song with multiple keys in a signle row.
  getSongList (songRawData) {
    const songID = []
    const songList = []
    let i = -1
    for (const [, songDetail] of songRawData.entries()) {
      if (typeof songDetail.key === 'string') {
        if (songID.includes(songDetail.nid)) {
          songList[i].keyFilter.push(songDetail.key.toLowerCase().substring(0, 1))
          songList[i].key.push({
            alias: songDetail.alias,
            nid: songDetail.nid,
            key: songDetail.key,
            chordId: songDetail.chordId,
            chordAlias: songDetail.chordAlias.substr(1)
          })
        } else {
          i++
          songList[i] = songDetail
          songList[i].keyFilter = [songDetail.key.toLowerCase().substring(0, 1)]
          songList[i].key = [{
            alias: songDetail.alias,
            nid: songDetail.nid,
            key: songDetail.key,
            chordId: songDetail.chordId,
            chordAlias: songDetail.chordAlias.substr(1)
          }]
          songID.push(songDetail.nid)
        }
      }
    }

    return songList
  }

  render () {
    const value = {
      isFetching: this.state.isFetching,
      fetchFailed: this.state.fetchFailed,
      scaleFactor: this.state.scaleFactor,
      setScaleFactor: (scaleFactor) => (
        this.setState({
          scaleFactor: scaleFactor
        })
      ),
      hero: this.state.hero,
      lastUpdated: this.state.lastUpdated,
      list: this.state.songs,
      accessToken: this.state.accessToken,
      accessTokenExpires: this.state.accessTokenExpires,
      accessTokenNeedsRefresh: () => this.accessTokenNeedsRefresh(),
      getAuthToken: () => this.getAuthToken()
    }

    // Waiting for site config.
    if (this.state.siteOnline === null) {
      return (
        <>
          <Page12><br />{this.state.message}</Page12>
          <SongsContext.Provider value={value}>
            <SongSampleText />
          </SongsContext.Provider>
        </>
      )
    }

    // Site offline.
    if (this.state.siteOnline === false) {
      return (
        <Offline
          offlineMessage = {this.state.offlineMessage}
        />
      )
    }

    // Site online.

    return (
      <SongsContext.Provider value={value}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/songs/:alias/:key?" component={Song} />
            <Route path="/songs" exact component={SongListing} />
            <Route path="/search" exact component={Search} />
            <Route path="/" component={Error404} />
          </Switch>
        </Router>
      </SongsContext.Provider>
    )
  }
}

export default App
