import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import { Hero } from 'components/Hero'
import { Link } from 'react-router-dom'
import SongSearchForm from 'components/song/SongSearchForm'
import { SongsContext } from 'utils/song-context'

class Home extends React.Component {
  static contextType = SongsContext
  render () {
    return (
      <Base>
        <Hero
          title="黑雲的詩歌結他譜 Collection"
          hero={this.context.hero}
          info={<p>希望各位好好利用本頁的詩歌結他譜作事奉<br />Last updated: {this.context.lastUpdated}</p>}
        />
        <Page12>
          <br />
          <SongSearchForm />
          <br />
          <Link to={{ pathname: '/songs' }}>按此查看所有詩歌</Link>
        </Page12>
      </Base>
    )
  }
}

export default Home
