import React, { Component } from 'react'
import renderHTML from 'react-render-html'
import styled from 'styled-components'
import { Link } from "react-router-dom"
import { Table } from 'react-bootstrap'
import {SongFiltersContext, SongsContext} from 'utils/song-context'
import SongFilters from 'components/song/SongFilters'

class SongList extends Component {
  
  static contextType = SongsContext

  constructor() {
    super()
    this.state = {
      filters: [],
      filteredSongs: [],
      songs: [],
      isFetching: true,
      fetchFailed: false
    }

  }

  componentDidMount() {

    if (this.state.songs.length === 0) {
      const songs = this.context.list
      this.setState ({
        songs: songs,
        filteredSongs: songs,
        isFetching: this.context.isFetching,
        fetchFailed: this.context.fetchFailed
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.isFetching !== this.context.isFetching) {
      this.setState ({
        isFetching: this.context.isFetching,
        fetchFailed: this.context.fetchFailed
      })    
    }

    const songs = this.context.list
    if (songs !== this.state.songs) {
      
      this.setState ({
        songs: songs,
        filteredSongs: songs,
      })
    }
  
  }

  filterSongs = (filters) => {

    const songs = this.state.songs
    let filteredSongs = songs

    // Language filter.
    filteredSongs = filters.lang ? filteredSongs.filter(song => song.lang === filters.lang) : filteredSongs
    
    // Woud counts filter.
    if (filters.wordCount) {
      if (filters.wordCount === '9') {
        filteredSongs = filters.wordCount ? 
          filteredSongs.filter(song => Number(song.count) >= Number(filters.wordCount)) : 
          filteredSongs
      } 
      else {
        filteredSongs = filters.wordCount ? filteredSongs.filter(song => song.count === filters.wordCount) : filteredSongs
      }
    }

    // Key filter.
    if (filters.key) {
      filteredSongs = filters.key ? filteredSongs.filter(song => song.keyFilter.includes(filters.key)) : filteredSongs
    }  

    this.setState ({
      filters: filters,
      filteredSongs: filteredSongs
    })
  }
  
  render () {
    let filteredSongs = this.state.filteredSongs

    if (filteredSongs.length > 0) {
      return (
        <Styles>
            <SongFiltersContext.Provider value={this.filterSongs}>
              <SongFilters filters={this.state.filters} />   
              <Count count={filteredSongs.length} />
              <SongTable songList={filteredSongs} />
            </SongFiltersContext.Provider>
          </Styles>
      )
    }
    else {
      if (this.state.isFetching) {
        return (<div>處理中，請稍候...</div>)
      }

      if (this.state.fetchFailed) {
        return (<div>無法讀取數據，請稍候重試。</div>)
      }
    }

  }
  
}
export default SongList

export const Count = (props) => (

  <p>共有{props.count}首詩歌</p>

)

export const SongTable = (props) => {
  
  return(

  <Table striped bordered hover>
    <thead>
      <tr>
        <th>歌名</th>
        <th>首句歌詞</th>
        <th>聲調</th>
      </tr>
    </thead>
    <tbody>
      {props.songList.map((songDetail, index) => {

        return (
          <tr key={index.toString()}>
            <td >
              <Link className="name"
                  to={{
                    pathname: `/songs${songDetail.alias}`,
                    state: { 
                      songID: songDetail.nid,
                      songName: songDetail.name,
                      songBeat: songDetail.beat,
                      songKeys: songDetail.key,
                      chordId: songDetail.key[0].chordId,
                      chordAlias: songDetail.key[0].chordAlias
                    }
                  }}>
                  {renderHTML(songDetail.name)}
              </Link>
            </td>
            <td >
              <Link className="first"
                  to={{
                    pathname: `/songs${songDetail.alias}`,
                    state: { 
                      songID: songDetail.nid,
                      songName: songDetail.name,
                      songBeat: songDetail.beat,
                      songKeys: songDetail.key,
                      chordId: songDetail.key[0].chordId,
                      chordAlias: songDetail.key[0].chordAlias
                    }
                  }}>
                {songDetail.first}
              </Link>
            </td>
            <td className="key">
              {songDetail.key.map((keyLink, keyIndex) => {
                return (
                  <Link 
                    key={keyIndex.toString()}
                    to={{
                      pathname: `/songs/${keyLink.chordAlias}`,
                      state: { 
                        songID: songDetail.nid,
                        songName: songDetail.name,
                        songBeat: songDetail.beat,
                        songKeys: songDetail.key,
                        chordId: keyLink.chordId,
                        chordAlias: keyLink.chordAlias
                      }
                    }}>
                    {renderHTML(keyLink.key)}
                  </Link>
                )}
              )}
            </td>
          </tr>
        )}
      )}
    </tbody>  
  </Table>

)}

const Styles = styled.div`

    td {
      padding: 0;
    }
    a.name,
    a.first {
      display: block;
      padding: 0.75rem;
    }
    td.key {
      padding: 0.75rem;
      a {
        padding-right: .5rem;
      }
    }
`   