import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import { Hero } from 'components/Hero'
import { SongsContext } from 'utils/song-context'

class Contact extends React.Component {
  static contextType = SongsContext
  render () {
    return (
      <Base>
        <Hero
          title="聯絡我"
          hero={this.context.hero}
        />
        <Page12>
          <p>觀迎加入<a href="http://www.facebook.com/group.php?gid=275171066630">Facebook群組</a>，或以電郵、facebook聯絡我</p>
          <p>
            ken1002.wfli@gmail.com<br />
            <a href="http://www.facebook.com/kumoli1002">http://www.facebook.com/kumoli1002</a><br />
          </p>
          <p>願主耶穌祝福你!</p>
        </Page12>
      </Base>
    )
  }
}

export default Contact
