import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import SongSearchForm from "components/song/SongSearchForm"
import SongSearchResults from "components/song/SongSearchResults"
import queryString from 'query-string'
import API from "utils/API"
import { SongsContext } from 'utils/song-context'

class Search extends React.Component {

  static contextType = SongsContext

  constructor(props) {
    super(props)
    this.state = {
      songs: [],
      accessToken: '',
      fetched: false,
      isFetching: false
    }
  }

  componentDidMount() {

    if (this.context.accessTokenNeedsRefresh() === true) {
      this.context.getAuthToken()
    }

    const accessToken = this.state.accessToken
    const fetched = this.state.fetched
    const isFetching = this.state.isFetching
    
    // Update access token. 
    if (accessToken !== this.context.accessToken) {
      this.setState({
        accessToken: this.context.accessToken
      })
    }

    if (accessToken !== '' && fetched === false && isFetching === false) {
      this.getSearchResults()
    }

  }

  componentDidUpdate(prevProps) {

    const accessToken = this.state.accessToken
    const fetched = this.state.fetched
    const isFetching = this.state.isFetching

    if (this.props.location.search !== prevProps.location.search) {
      this.getSearchResults()
    }

    if (accessToken !== '' && fetched === false && isFetching === false) {
      this.getSearchResults()
    }
  }
  
  render() {

    const searchQuery = queryString.parse(this.props.location.search)

    const defaultkey = searchQuery.key !== undefined ? searchQuery.key : ""
   
    let body = ""

    if (this.state.isFetching) {
      body = <div><br />處理中，請稍候...</div>
    }
    else if (this.state.fetchFailed) {
      body = <div><br />無法讀取數據，請稍候重試。</div>
    }
    else {
      body = <SongSearchResults songs={this.state.songs} />
    }


    return (
      <Base>
      <Page12>
        <h1>搜尋結果</h1>
        <SongSearchForm defaultkey={defaultkey} />
        { body } 
      </Page12>
    </Base>
    )
  }    
  
  async getSearchResults() {

    this.setState({
      isFetching: true
    })   

    try {
      
      const searchQuery = queryString.parse(this.props.location.search)
      const accessToken = this.state.accessToken

      const response = await API.get('kenli1002/search', {
        params: {
          key: searchQuery.key
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }    
      });

      this.setState({
        isFetching: false,
        fetched: true,
        songs: response.data
      })     
      
    } catch (e) {

      this.setState({
        isFetching: false
      })

      // Unauthorized.
      if (typeof(e.response) != 'undefined' && e.response.status === 401) {
        this.context.getAuthToken()        
      }
      else {
        this.setState({
          fetchFailed: true
        })      
      }
    }
  }
}

export default Search
