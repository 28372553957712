import React, { Component } from 'react'
import styled from 'styled-components'
import { SongsContext } from 'utils/song-context'

class SongContent extends Component {
  
  static contextType = SongsContext

  constructor (props) {
    super(props)
    this.chordsRef = React.createRef()
  }

  componentDidUpdate () {
    const scaleFactor = this.context.scaleFactor
    let arr = []

    const scaleContainers = this.chordsRef.current.getElementsByClassName('scale-container')
    arr = [...scaleContainers]
    arr.map((scaleContainer) => {
      scaleContainer.style.width = ''
      const elementWidth = scaleContainer.offsetWidth
      scaleContainer.style.width = `${elementWidth * scaleFactor}px`
      return true
    })

    const halfWidthElements = this.chordsRef.current.getElementsByClassName('font-width--half')

    arr = [...halfWidthElements]
    arr.map((halfWidthElement) => {
      halfWidthElement.style.transform = `scaleX(${scaleFactor})`
      return true
    })
  }

  render () {
    const songChords = this.props.songChords
    if (songChords === '') {
      return <div ref={this.chordsRef}>處理中，請稍候...</div>
    } else {
      return (
        <ChordsStyles className="chords" ref={this.chordsRef} dangerouslySetInnerHTML={{ __html: songChords }} />
      )
    }
  }
}

export default SongContent

const ChordsStyles = styled.div`

  font-family: monospace;
  font-size: 1.5rem;

  .font-width--half {
    display: inline-block;
    transform-origin: 0 50%;
  }
  
  .scale-container {
    display: inline-block;
  }
`
