import React, { Component } from 'react'
import { Nav } from 'react-bootstrap'
import styled from 'styled-components'
import SongChords from 'components/song/SongChords'
import renderHTML from 'react-render-html'
import { Link } from 'react-router-dom'

class SongContent extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.chordAlias !== prevProps.chordAlias) {
      this.render()
    }
  }

  render () {
    return (
      <SongContentStyle>
        <h1>{renderHTML(this.props.songName)} {this.props.songBeat ? '(' + this.props.songBeat + ')' : null}</h1>
        <Nav variant="tabs" defaultActiveKey={`/songs/${this.props.chordAlias}`} >
          {
            this.props.songKeys.map((songKey, index) => {
              const path = `/songs/${songKey.chordAlias}`
              const title = songKey.key

              return (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={path} as={Link}
                    active = {this.props.chordAlias === songKey.chordAlias}
                    to={{
                      pathname: path,
                      state: {
                        songID: songKey.nid,
                        songName: this.props.songName,
                        songBeat: this.props.songBeat,
                        songKeys: this.props.songKeys,
                        chordId: songKey.chordId,
                        chordAlias: songKey.chordAlias
                      }
                    }}
                  >
                    {renderHTML(title)}
                  </Nav.Link>
                </Nav.Item>
              )
            })
          }
        </Nav>
        <SongChords
          songChords={this.props.songChords}
        />
      </SongContentStyle>

    )
  }
}

export default SongContent

const SongContentStyle = styled.div`
  h1 {
    font-size: 1.4rem;
    margin-bottom: .5rem;
    padding: 0;
  }
  a.nav-link a {
    display: block;
  }

`
