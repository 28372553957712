import React from 'react'
import Topnav from 'components/Topnav'
import { Footer } from 'components/Footer'

export const Base = (props) => (
  <React.Fragment>
    <Topnav />
    {props.children}
    <Footer />
  </React.Fragment>
)
