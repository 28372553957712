import axios from 'axios'
import qs from 'qs'

const prod = {
  url: 'https://dev-uen.pantheonsite.io/'
}

const dev = {
  url: 'http://uen.localhost/'
//  url: 'http://local.uen/'
}

export const REST = process.env.NODE_ENV === 'development' ? dev : prod

const API = axios.create({
  baseURL: REST.url,
  responseType: 'json',
  timeout: 5000
})

export default API

const OauthHeaders = {
  url: 'oauth/token',
  data: qs.stringify({
    grant_type: 'client_credentials',
    client_id: '821e2a07-9353-440f-bc8f-695180d1d209',
    client_secret: 'rest-consumer'
  })
}

export function Oauth () {
  return API.post(OauthHeaders.url, OauthHeaders.data)
}
