import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import { Link } from 'react-router-dom'

export const Error404 = () => (
  <Base>
    <Page12 title="404 Error">
      <p>Page not found.</p>
      <Link to="/">&lt; Back to home</Link>
    </Page12>
  </Base>

)
