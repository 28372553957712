import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import SongList from 'components/song/SongList'

class SongListing extends React.Component {
  render () {
    return (
      <Base>
        <Page12>
          <h1>詩歌索引</h1>
          <SongList />
        </Page12>
      </Base>
    )
  }
}

export default SongListing
