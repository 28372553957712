import React from 'react'
import { Page12 } from 'layouts/Page12'
import { Hero } from 'components/Hero'

export const Offline = (props) => (
  <>
    <Hero
      title="黑雲的詩歌結他譜 Collection"
    />
    <Page12>
      <br />
      <p className="h4">{props.offlineMessage}</p>
      <br />
    </Page12>
  </>
)
