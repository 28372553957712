import React from 'react'
import { Container } from 'react-bootstrap'
import { REST } from 'utils/API'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Hero = (props) => {
  return (
    <Styles hero={props.hero}>
      <Container fluid="lg">
        <h1>{props.title}</h1>
        {props.info ? props.info : null}
      </Container>
    </Styles>

  )
}

Hero.propTypes = {
  title: PropTypes.string,
  hero: PropTypes.object,
  info: PropTypes.element
}

const Styles = styled.div`
  
  height: 220px;
  position: relative;
  padding: 2rem 0;
  margin-bottom: 1rem;
  text-shadow: 2px 2px #444;

  h1 {
    text-shadow: 3px 3px #444;
  }
  
  ::after {
    content: "";
    background-size: cover;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   

    background: url(${REST.url}${props => props.hero.xs}) no-repeat top;

    @media (min-width: 576px) {
      background: url(${REST.url}${props => props.hero.sm}) no-repeat top;
    }
    @media (min-width: 768px) {
      background: url(${REST.url}${props => props.hero.md}) no-repeat top;
    }
    @media (min-width: 992px) {
      background: url(${REST.url}${props => props.hero.lg}) no-repeat top;
    }
    @media (min-width: 1200px) {
      background: url(${REST.url}${props => props.hero.wide}) no-repeat top;
    }
  }

`
