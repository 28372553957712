import React from 'react'

export const defaultFilters = {
  wordCount: '',
  lang: '',
  key: ''
}
export const SongFiltersContext = React.createContext({
  filters: defaultFilters,
  setFilters: () => {}
})

export const SongsContext = React.createContext({
  songs: []
})
