import React, { Component } from 'react'
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

class SongSearchForm extends Component {

  constructor(props) {
    super(props)
    this.searchKeyRef = React.createRef()
    this.state = {
      searchKey: ""
    }
  }
  
  componentDidMount() {
    
    this.setState({
      searchKey: this.props.defaultkey
    })
  }
  
  componentDidUpdate(prevProps) {
  
    if (this.props.location.search !== prevProps.location.search) {

      const searchQuery = queryString.parse(this.props.location.search)
      this.setState({
        searchKey: searchQuery.key
      })
    }
    
  }

  handleSubmit = event => {

    event.preventDefault()  

    const searchKey = this.searchKeyRef.current.value

    this.props.history.push({
      pathname: '/search',
      search: `?key=${searchKey}`,
    }); 
  }

  skOnChange = event => {
    this.setState({
      searchKey: event.target.value
    })
  }

  render() {

    const searchValue = this.state.searchKey !== undefined ? this.state.searchKey : ""
    return (
        <Form onSubmit={this.handleSubmit}>
          <InputGroup>
            <FormControl
              placeholder="搜尋"
              aria-label="搜尋"
              ref={this.searchKeyRef}
              value={searchValue}
              onChange={this.skOnChange}
            />
            <InputGroup.Append>
              <Button variant="primary" type="submit">搜尋詩歌</Button>
            </InputGroup.Append>
          </InputGroup>
          <small id="emailHelp" className="form-text text-muted">請輸入至少3個字作搜尋</small>
        </Form>
    )
  }
}

export default withRouter(SongSearchForm)