import React, { Component } from 'react'
import { Col, Form, Button } from 'react-bootstrap'
import { SongFiltersContext } from 'utils/song-context'

class SongFilters extends Component {

  static contextType = SongFiltersContext

  constructor(props) {
    super(props)
    this.wordCountRef = React.createRef()
    this.langRef = React.createRef()
    this.keyRef = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      disableWordCount: false,
      wordCountVal: ""
    }
  
  }

  
  handleSubmit = event => {

    event.preventDefault()  
    const lang = this.langRef.current.value
    this.context ({
      wordCount: this.wordCountRef.current.value,
      lang: lang,
      key: this.keyRef.current.value 
    }) 

  }

  langOnChange = event => {
    const lang = event.target.value
    
    if (lang !== 'zh' && lang !== '') {
      this.setState ({
        disableWordCount: true,
        wordCountVal: ""
      })  
    }
    else {
      this.setState ({
        disableWordCount: false
      })
    }
    
  }

  wcOnChange = event => {
    this.setState({
      wordCountVal: event.target.value
    })
  }

  render() {

    const filters = this.props.filters
    const lang = this.props.filters.lang  
    
    let disableWordCount = this.state.disableWordCount
    if (lang !== undefined && lang !== 'zh' && lang !== '') {
      disableWordCount = true
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Row>
        <Form.Group as={Col} controlId="songFilter.Language">
            <Form.Label>語言</Form.Label>
            <Form.Control as="select" ref={this.langRef} defaultValue={filters.lang} onChange={this.langOnChange}>
              <option value="">全部</option>
              <option value="zh">中文</option>
              <option value="en">英文</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="songFilter.WordCount">
            <Form.Label>歌名字數</Form.Label>
            <Form.Control as="select" 
              {...(disableWordCount ? {value:this.state.wordCountVal} : {} )}
              ref={this.wordCountRef} 
              defaultValue={filters.wordCount} 
              disabled={disableWordCount}
              onChange={this.wcOnChange}
            >
              <option value="">全部</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9+</option>
            </Form.Control>
            <small id="emailHelp" className="form-text text-muted">只適用於中文詩歌</small>
          </Form.Group>
          <Form.Group as={Col} controlId="songFilter.Key">
            <Form.Label>聲調</Form.Label>
            <Form.Control as="select" ref={this.keyRef} defaultValue={filters.key}>
              <option value="">全部</option>
              <option value="c">C</option>
              <option value="d">D</option>
              <option value="e">E</option>
              <option value="f">F</option>
              <option value="g">G</option>
              <option value="a">A</option>
              <option value="b">B</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Button variant="primary" type="submit" block>
              篩選
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    )
  }
}

export default SongFilters