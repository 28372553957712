import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'

export const Page12 = (props) => (
  <React.Fragment>
    <Container fluid="lg">
      {props.title ? <h1>{props.title}</h1> : null}
      {props.children}
    </Container>
  </React.Fragment>
)

Page12.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array
}
