import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'

class Topnav extends Component {
  render () {
    return (
      <>
        <Navbar variant="dark" bg="dark" expand="sm">
          <Navbar.Brand>
            <Link to={{ pathname: '/' }}>
              <img
                src="/logo.png"
                width="30"
                height="30"
                alt="Kenli1002 logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"
              onSelect={(selectedKey) => this.props.history.push(selectedKey)}
            >
              <Nav.Link eventKey='/'> 詩歌搜尋 </Nav.Link>
              <Nav.Link eventKey='/songs'> 詩歌索引 </Nav.Link>
              <Nav.Link eventKey='/contact'> 聯絡我 </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>

    )
  }
}

export default withRouter(Topnav)

export const BrandOnlyTopnav = () => (
  <>
    <Navbar variant="dark" bg="dark" expand="sm">
      <Navbar.Brand>
        <img
          src="/logo.png"
          width="30"
          height="30"
          alt="Kenli1002 logo"
        />
      </Navbar.Brand>
    </Navbar>
  </>

)
