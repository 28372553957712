import React, { Component } from 'react'
import renderHTML from 'react-render-html'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class SongSearchResults extends Component {
  render () {
    const songs = this.props.songs

    let noResultsMsg = ''
    if (songs.length === 0) {
      noResultsMsg = <Link to={{ pathname: '/songs' }}>按此查看所有詩歌</Link>
    }

    return (
      <Styles>
        <p><br />找到{ songs.length }首詩歌</p>
        { noResultsMsg }
        { songs.map(function (song, i) {
          return (
            <div className='result-item' key={i}>
              <h2>
                <Link
                  to={{
                    pathname: `/songs${song.alias}`
                  }}>
                  {renderHTML(song.title)}
                </Link>
              </h2>
              <div className="excerpt">{renderHTML(song.excerpt)}</div>
            </div>
          )
        })
        }
      </Styles>

    )
  }
}

SongSearchResults.propTypes = {
  songs: PropTypes.array
}

export default SongSearchResults

export const Styles = styled.div`
  color: rgba(255,255,255,0.8);
  
  h2 {
    font-size: 1.3em;
  }

  a {
    color: rgba(255,255,255,1);
  }

  strong {
    color: rgba(255,255,255,1);
  }

  .result-item {
    padding-bottom: 2em;
  }

  .excerpt {
    padding-left: 1em;
  }
`
