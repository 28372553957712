import React from 'react'
import { Base } from 'templates/Base'
import { Page12 } from 'layouts/Page12'
import SongContent from 'components/song/SongContent'
import API from "utils/API"
import { SongsContext } from 'utils/song-context'

class Song extends React.Component {
  
  static contextType = SongsContext

  state = {
    accessToken: '',
    fetched: false,
    isFetching: false,
    songNotFound: false,
    songName: "",
    songBeat: "",
    songKeys: [],
    chordAlias: "",
    songChords: "",
    scaleFactor: 1
  };

  componentDidMount() {

    if (this.context.accessTokenNeedsRefresh() === true) {
      this.context.getAuthToken()
    }

    const accessToken = this.state.accessToken
    const fetched = this.state.fetched
    const isFetching = this.state.isFetching

    // Update access token. 
    if (accessToken !== this.context.accessToken) {
      this.setState({
        accessToken: this.context.accessToken
      })
    }

    if (accessToken !== '' && fetched === false && isFetching === false) {
      this.fetchData()
    }

  }

  componentDidUpdate(prevProps) {
    
    const accessToken = this.state.accessToken
    const fetched = this.state.fetched
    const isFetching = this.state.isFetching

    // When refresh on song page.
    if (this.props.location.state !== prevProps.location.state) {
      this.fetchData()
    }

    // Update access token. 
    if (accessToken !== this.context.accessToken) {
      this.setState({
        accessToken: this.context.accessToken
      })
    }

    if (accessToken !== '' && fetched === false && isFetching === false) {
      this.fetchData()
    }

    const scaleFactor = this.context.scaleFactor
    if (scaleFactor !== this.state.scaleFactor) {
      this.setState ({
        scaleFactor: scaleFactor,
      })
    }
  }

  render() {

    const songName = this.state.songName
    const songBeat = this.state.songBeat
    const songKeys = this.state.songKeys
    const chordAlias = this.state.chordAlias
    const songChords = this.state.songChords
    const scaleFactor = this.state.scaleFactor

    return (
      <Base>
        <Page12>
        <SongContent 
          songName={ songName }
          songBeat={ songBeat }
          songChords={ songChords }
          songKeys={ songKeys }
          chordAlias={ chordAlias }
          scaleFactor={ scaleFactor }
        /><></>
        </Page12>
      </Base>
    ) 
  }  
 
  handleFetchErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  }

  async getChordByID(chordId) {

    const accessToken = this.state.accessToken

    try {    
      const response = await API.get('kenli1002/chords/' + chordId, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }          
      })
      const data = response.data[0]

      this.setState({
        songChords: data.chords,
        fetched: true,
        isFetching: false
      })   
    } catch (e) {
      
      this.setState({
        isFetching: false
      })

      // Unauthorized.
      if (typeof(e.response) !== 'undefined' && e.response.status === 401) {
        this.context.getAuthToken()
      }
      else {
        this.setState({
          fetchFailed: true
        })        
      }
    }
    
  }

  async getSongByAlias() {

    const accessToken = this.state.accessToken
    const songAlias = `/${this.props.match.params.alias}`
    const key = this.props.match.params.key

    try {    

      const response = await API.get('kenli1002/chords-by-song-alias/', {
        params: {
          alias: `/kenli1002${songAlias}`
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })

      const data = response.data

      this.setState({
        fetched: true,
        isFetching: false
      })  

      // Get song info.
      this.setState({
        songName: data[0].name,
        songBeat: data[0].beat,
      }) 

      // Prepare songKeys array.
      let songKeys = []
      for (const [, songDetail] of data.entries()) {

        songKeys.push({
          alias: songDetail.alias,
          nid: songDetail.nid,
          key: songDetail.key,
          chordId: songDetail.chordId,
          chordAlias: songDetail.chordAlias.substr(1)  
        })
      }

      this.setState({
        songKeys: songKeys
      }) 
        
      // If key is provided in URL.
      if (typeof key !== 'undefined') {
        
        const chordAlias = `${songAlias}/${key}`
        
        // Find the chords from chordAlias.
        const song = (data.filter(song => chordAlias === song.chordAlias))
        this.setState({
          songChords: song[0].chords,
          chordAlias: chordAlias.substr(1)
        })     
      }
      else {

        // Use the first chords.
        this.setState({
          songChords: data[0].chords,
          chordAlias: data[0].chordAlias.substr(1)
        })          
      }
    } catch (e) {

      this.setState({
        isFetching: false
      })

      // Unauthorized.
      if (typeof(e.response) != 'undefined' && e.response.status === 401) {
        this.context.getAuthToken()
      } else {
        this.setState({
          fetchFailed: true
        })    
      }
    }

  }

  fetchData() {

    this.setState({
      isFetching: true
    })   

    // Render song from song list.
    if (this.props.location.state) {
      const chordId = this.props.location.state.chordId

      this.setState({
        songName: this.props.location.state.songName,
        songBeat: this.props.location.state.songBeat,
        songKeys: this.props.location.state.songKeys,
        chordAlias: this.props.location.state.chordAlias
      }) 

      this.getChordByID(chordId)
    }
    // Render song from direct URL.
    else {
      this.getSongByAlias()
    }
  }
}

export default Song

